import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { layout, container, contact, left, right, content, header, hasHeader, thanks, headerContent } from './summer-contest-layout.module.scss';

const leftImg = '../assets/images/summer-contest/summer-left.png';
const rightImg = '../assets/images/summer-contest/summer-right.png';

import { grid } from '../styles/grid.module.scss';
import staticFiles from "../config/static-files";

import MainLayout, { IMainLayoutProps } from "./main-layout";
import ContestContact from "../components/organisms/contest-contact";
import ContestThankYou, { IContestThankYouProps } from "../components/organisms/contest-thank-you";

interface ISummerContestLayout extends IMainLayoutProps {
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
}

const SummerContestLayout: React.FC<ISummerContestLayout> = ({ children, className = '', headerProps, ...rest }) => {
    return (
        <MainLayout {...rest} className={`${layout} ${headerProps ? hasHeader : ''}`}>
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    <div className={left}>
                        <StaticImage
                            src={leftImg}
                            alt=""
                        />
                    </div>
                    <div className={right}>
                        <StaticImage
                            src={rightImg}
                            alt=""
                        />
                    </div>
                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className} ${content}`}>
                    <div className={`${left} left`}>
                        <StaticImage
                            src={leftImg}
                            alt=""
                        />
                    </div>
                    <div className={`${right} right`}>
                        <StaticImage
                            src={rightImg}
                            alt=""
                        />
                    </div>
                    { children }
                </div>
                <ContestContact className={contact} regulationsUrl={staticFiles.summerContestRegulations} />
            </div>
        </MainLayout>
    )
}

export default SummerContestLayout;