import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const getInitialSummerContestWinnerFormValues = (token: string) => {
    return {
        token: token,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        zipCode: '',
        city: '',
        recaptcha: '',
    };
};

export function getSummerContestWinnerFormSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape({
        firstname: Yup.string().required(t('form.required.error')),
        lastname: Yup.string().required(t('form.required.error')),
        email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
        phone: Yup.string().required(t('form.required.error')),
        street: Yup.string().required(t('form.required.error')),
        houseNumber: Yup.string().required(t('form.required.error')),
        apartmentNumber: Yup.string(),
        zipCode: Yup.string().required(t('form.required.error')),
        city: Yup.string().required(t('form.required.error')),
        recaptcha: Yup.string().required(),
    });
}
