import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import {
    innerWrapper,
    inputGrid,
    input,
    fullWidth,
    button,
    recaptchaBadge,
} from './summer-contest-winner-form.module.scss';

import pagesContext from '../../config/pages-context';
import useContestTokenCheck from '../../hooks/use-contest-token-check';
import { getSummerContestCheckToken, postSummerContestWinner } from '../../api-ssr/summer-contest';
import {
    getInitialSummerContestWinnerFormValues,
    getSummerContestWinnerFormSchema,
} from '../../formik/summer-contest-winner.config';

import Loader from '../atoms/loader';
import Button from '../atoms/button';
import FormField from '../atoms/form-field';
import FormInput from '../atoms/form-input';
import Captcha from '../atoms/captcha';

interface ISummerContestWinnerForm {
    className: string;
    handleSuccess: () => void;
}

const SummerContestWinnerForm: React.FC<ISummerContestWinnerForm> = ({ className,handleSuccess }) => {
    const { t, language } = useI18next();
    const [loading, setLoading] = useState(true);

    const onLoad = () => {
        setLoading(false);
    };

    const redirectPages = {
        default: pagesContext.summerContest.slug,
        expired: pagesContext.summerContestExpired.slug,
    };

    const token = useContestTokenCheck(language, getSummerContestCheckToken, redirectPages, onLoad);

    const mutation = useMutation(postSummerContestWinner, {
        onSuccess: () => {
            handleSuccess();
        },
        onError: () => {
            alert(t('api.error'));
        },
    });

    const { isLoading } = mutation;

    return (
        <div className={`${className}`}>
            {loading && <Loader />}
            <Formik
                initialValues={getInitialSummerContestWinnerFormValues(token)}
                validationSchema={getSummerContestWinnerFormSchema(t)}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    mutation.mutate({ ...values });
                }}
            >
                {({ errors, touched }) => (
                    <Form className={innerWrapper}>
                        <div className={inputGrid}>
                            <FormField
                                id={'firstname'}
                                hintText={
                                    errors.firstname && touched.firstname ? errors.firstname : null
                                }
                                errorStyle={!!(errors.firstname && touched.firstname)}
                            >
                                <FormInput
                                    id={'firstname'}
                                    name={'firstname'}
                                    placeholder={t('summer.contest.winner.firstname')}
                                    errorStyle={!!(errors.firstname && touched.firstname)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'lastname'}
                                hintText={
                                    errors.lastname && touched.lastname ? errors.lastname : null
                                }
                                errorStyle={!!(errors.lastname && touched.lastname)}
                            >
                                <FormInput
                                    id={'lastname'}
                                    name={'lastname'}
                                    placeholder={t('summer.contest.winner.lastname')}
                                    errorStyle={!!(errors.lastname && touched.lastname)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'email'}
                                hintText={errors.email && touched.email ? errors.email : null}
                                errorStyle={!!(errors.email && touched.email)}
                            >
                                <FormInput
                                    id={'email'}
                                    name={'email'}
                                    placeholder={t('summer.contest.winner.email')}
                                    errorStyle={!!(errors.email && touched.email)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'phone'}
                                hintText={errors.phone && touched.phone ? errors.phone : null}
                                errorStyle={!!(errors.phone && touched.phone)}
                            >
                                <FormInput
                                    id={'phone'}
                                    name={'phone'}
                                    placeholder={t('summer.contest.winner.phone')}
                                    errorStyle={!!(errors.phone && touched.phone)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'street'}
                                hintText={errors.street && touched.street ? errors.street : null}
                                errorStyle={!!(errors.street && touched.street)}
                            >
                                <FormInput
                                    id={'street'}
                                    name={'street'}
                                    placeholder={t('summer.contest.winner.street')}
                                    errorStyle={!!(errors.street && touched.street)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'houseNumber'}
                                hintText={
                                    errors.houseNumber && touched.houseNumber
                                        ? errors.houseNumber
                                        : null
                                }
                                errorStyle={!!(errors.houseNumber && touched.houseNumber)}
                            >
                                <FormInput
                                    id={'houseNumber'}
                                    name={'houseNumber'}
                                    placeholder={t('summer.contest.winner.house')}
                                    errorStyle={!!(errors.houseNumber && touched.houseNumber)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'apartmentNumber'}
                                hintText={
                                    errors.apartmentNumber && touched.apartmentNumber
                                        ? errors.apartmentNumber
                                        : null
                                }
                                errorStyle={!!(errors.apartmentNumber && touched.apartmentNumber)}
                            >
                                <FormInput
                                    id={'apartmentNumber'}
                                    name={'apartmentNumber'}
                                    placeholder={t('summer.contest.winner.apartment')}
                                    errorStyle={
                                        !!(errors.apartmentNumber && touched.apartmentNumber)
                                    }
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'zipCode'}
                                hintText={errors.zipCode && touched.zipCode ? errors.zipCode : null}
                                errorStyle={!!(errors.zipCode && touched.zipCode)}
                            >
                                <FormInput
                                    id={'zipCode'}
                                    name={'zipCode'}
                                    placeholder={t('summer.contest.winner.zipcode')}
                                    errorStyle={!!(errors.zipCode && touched.zipCode)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'city'}
                                hintText={errors.city && touched.city ? errors.city : null}
                                errorStyle={!!(errors.city && touched.city)}
                                className={fullWidth}
                            >
                                <FormInput
                                    id={'city'}
                                    name={'city'}
                                    placeholder={t('summer.contest.winner.city')}
                                    errorStyle={!!(errors.city && touched.city)}
                                    className={input}
                                />
                            </FormField>
                        </div>
                        <Button isLoading={isLoading} className={button}>
                            {t('summer.contest.winner.submit')}
                        </Button>
                        {process.env.RECAPTCHA && (
                            <Captcha className={recaptchaBadge} siteKey={process.env.RECAPTCHA} />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SummerContestWinnerForm;
