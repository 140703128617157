import React from 'react';

import { container, content, title, accent, icon } from './contest-thank-you.module.scss';
import IconSent from '../../assets/images/svg/icon_completed.svg';

export interface IContestThankYouProps {
    className?: string;
    titleFirstLine?: string;
    titleSecondLine?: string;
    children?: React.ReactNode;
}

const ContestThankYou: React.FC<IContestThankYouProps> = ({ className = '', titleSecondLine, titleFirstLine, children }) => {
    return (
        <div className={`${container} ${className}`}>
            <IconSent className={icon} />
            {(titleFirstLine || titleSecondLine) && (
                <h1 className={title}>
                    {titleFirstLine && <span>{titleFirstLine}</span>}
                    {titleSecondLine && <span className={accent}>{titleSecondLine}</span>}
                </h1>
            )}
            <div className={`${content} content`}>
                {children}
            </div>
        </div>
    );
};

export default ContestThankYou;