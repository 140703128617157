import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { ISummerForm } from '../models/summer-form.model';
import { IDefaultContestWinnerForm } from '../models/default-contest-form.model';

export const summerContestFormMutation = (data: ISummerForm) => {
    return axios.post(prependApiUrl('/taste-of-summer/create'), {
        ...data,
    });
};

export const postSummerContestWinner = (createForm: IDefaultContestWinnerForm) => {
    return axios.post(prependApiUrl('/taste-of-summer/addresses'), {
        ...createForm,
    });
};

export const getSummerContestCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/taste-of-summer/check/${token}`), {});
};
