// extracted by mini-css-extract-plugin
export var contact = "summer-contest-layout-module--contact--7479e";
export var container = "summer-contest-layout-module--container--2109e";
export var content = "summer-contest-layout-module--content--f1f6a";
export var errorBlink = "summer-contest-layout-module--error-blink--baaad";
export var hasHeader = "summer-contest-layout-module--has-header--f73b8";
export var header = "summer-contest-layout-module--header--7b8d0";
export var headerContent = "summer-contest-layout-module--header-content--da749";
export var layout = "summer-contest-layout-module--layout--e1129";
export var left = "summer-contest-layout-module--left--7e7e7";
export var right = "summer-contest-layout-module--right--b9977";
export var thanks = "summer-contest-layout-module--thanks--16b08";