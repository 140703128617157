import React, {useState} from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from "react-query";
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    layout,
    header,
    icon,
    title,
    info,
    form,
    isSent,
} from './summer-contest-winner-page.module.scss';

import WinnerIcon from "../assets/images/svg/winner.svg";
import IconSent from '../assets/images/svg/icon_completed.svg';

import { IPage } from '../models/page.model';

import SummerContestLayout from "../layouts/summer-contest-layout";
import SummerContestWinnerForm from '../components/organisms/summer-contest-winner-form';

interface ISummerContestWinnerPage {
    readonly data: {
        page: IPage | null;
    };
}

const SummerContestWinnerPage: React.FC<ISummerContestWinnerPage> = ({ data }) => {
    const { page } = data;
    const { t } = useI18next();
    const [isSuccess, setIsSuccess] = useState(false);
    const queryClient = new QueryClient();

    const handleSuccess = () => {
        setIsSuccess(true);
    }

    return (
        <QueryClientProvider client={queryClient}>
            <SummerContestLayout className={`${layout} ${isSuccess ? isSent : ''}`} page={page}>
                {
                    isSuccess ?
                        <div className={header}>
                            <IconSent className={icon} />
                            <h1 className={title}>{t('summer.contest.winner.title.sent')}</h1>
                            <p className={info}>{t('summer.contest.winner.description.sent')}</p>
                        </div>
                        :
                        <>
                            <div className={header}>
                                <WinnerIcon className={icon} />
                                <h1 className={title}>{t('summer.contest.winner.title')}</h1>
                                <p className={info}>{t('summer.contest.winner.description')}</p>
                            </div>
                            <SummerContestWinnerForm className={form} handleSuccess={handleSuccess} />
                        </>
                }
            </SummerContestLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SummerContestWinnerPage;
